import * as React from 'react';
import { Link } from 'gatsby';

export default function IndexPage() {
  return (
    <main>
      <h1>Coming soon!!!</h1>
      <Link to='/about'>About this site</Link>
    </main>
  )
}